import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { initializeAxios } from '../../core/services/dataAccess/DataService.Axios';
import { actionTypes } from '../ActionTypes';
import { StatusType } from '../Common/NotificationStore';
import { AppThunkAction } from '../index';
import { AuthToasterError, OTPCheck } from '../../components/Common/Constants';
import { OTPPageConstants } from '../../components/Common/Constants';
import { OTPMobilePageConstants, DisplayError } from '../../components/Common/Constants';
import { container } from '../../startup/inversify.config';
import { ILocalStore } from '../../core/utilities/LocalStore';
import { TYPES } from '../../startup/types';
import { TelemetryLogger } from '../../Logger/AppInsights';

const logger = TelemetryLogger.getInstance();

export interface IOTPPage {
	loading: boolean;
	mobileNo: string;
}

const OTPPage: IOTPPage = {
	loading: false,
	mobileNo: '',
};

interface IClientResponse {
	IsSuccess: boolean;
	ErrorCode: string;
	ErrorDescription: string;
	Data: any;
}

const localStorage = container.get<ILocalStore>(TYPES.ILocalStore);

export const actionCreators = {
	otpPath:
		(clientId: string, navigate: (path: string) => void, handleError: () => void): AppThunkAction<any> =>
		(dispatch, getState) => {
			//add api url
			return initializeAxios(clientId)
				.get<any>('/api/K1/' + clientId)
				.then(function (response: AxiosResponse<any>) {
					let result = response.data;

					if (result.isSuccess) {
						navigate(result.data);
						localStorage.set('clientId', clientId);
					} else {
						let errorDesc = result.errorDescription;
						let errorCode = result.errorCode;

						if (errorCode && DisplayError.includes(errorCode)) {
							dispatch({
								type: actionTypes.SET_ERROR_MSG,
								data: errorDesc,
							});

							handleError();
						} else {
							if (typeof errorDesc === 'string') {
								dispatch({
									type: actionTypes.NOTIFICATION,
									statusMessage: errorDesc,
									statusType: StatusType.Error,
								});
								logger.trackWarning(`k1 shareholder otpPath failed with error ${errorDesc} for clientId: ${clientId}`);
							} else {
								logger.trackError(`k1 shareholder otpPath failed with error ${errorDesc} for clientId: ${clientId}`);
							}
						}
					}
				})
				.catch((e: any) => {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: OTPCheck.ServerError,
						statusType: StatusType.Error,
					});
					logger.trackWarning(`k1 shareholder otpPath failed with error ${e.message} for clientId: ${clientId}`);
				});
		},
	getClientInfo:
		(clientId?: string): AppThunkAction<any> =>
		(dispatch, getState) => {
			//put the api url
			dispatch({
				type: actionTypes.START_FETCH_INFO,
			});
			return initializeAxios(clientId)
				.get<any>('/api/K1/GetMFASettings/' + clientId)
				.then(function (response: any) {
					let result = response.data;
					if (result.isSuccess) {
						dispatch({
							type: actionTypes.SET_MOBILE_NO,
							data: result.data,
						});
					} else {
						dispatch({
							type: actionTypes.NOTIFICATION,
							statusMessage: OTPMobilePageConstants.ErrorMessage.clientFetchFailed,
							statusType: StatusType.Error,
						});
						dispatch({
							type: actionTypes.STOP_OTP_LOADER,
						});
					}
				})
				.catch(function (error: any) {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: OTPMobilePageConstants.ErrorMessage.serverError,
						statusType: StatusType.Error,
					});
					dispatch({
						type: actionTypes.STOP_OTP_LOADER,
					});
					logger.trackWarning(
						`k1 shareholder getClientInfo failed with error ${error.message} for clientId: ${clientId}`,
					);
				});
		},
	generateMobileOTP:
		(clientId?: string): AppThunkAction<any> =>
		(dispatch, getState) => {
			//put the api url
			return initializeAxios(clientId)
				.postJson<any>(null, '/api/K1/Mobile/Generate/' + clientId)
				.then(function (response: any) {
					let result = response.data;
					if (result) {
						dispatch({
							type: actionTypes.NOTIFICATION,
							statusMessage: OTPMobilePageConstants.SuccessMessage.OTPGenerateSuccess,
							statusType: StatusType.Success,
						});
					} else {
						dispatch({
							type: actionTypes.NOTIFICATION,
							statusMessage: OTPMobilePageConstants.ErrorMessage.OTPGenerateFail,
							statusType: StatusType.Error,
						});
					}
				})
				.catch(function (error: any) {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: OTPMobilePageConstants.ErrorMessage.serverError,
						statusType: StatusType.Error,
					});
					logger.trackWarning(
						`k1 shareholder generateMobileOTP failed with error ${error.message} for clientId: ${clientId}`,
					);
				});
		},
	verifyMobileOTP:
		(
			OTP: string,
			clientId?: string,
			handleVerify?: (guid: string) => void,
			handleError?: (result?: any) => void,
		): AppThunkAction<any> =>
		(dispatch, getState) => {
			return initializeAxios(clientId)
				.postJson<any>('"' + OTP + '"', '/api/K1/Text/Validate/' + clientId)
				.then(function (response: any) {
					let result = response.data;
					if (result.isSuccess) {
						if (handleVerify !== undefined) {
							localStorage.set('isLoggedIn', true);
							handleVerify(result.data);
						}
					} else {
						let errorDesc = result.errorDescription;
						let errorCode = result.errorCode;
						if (errorCode && DisplayError.includes(errorCode)) {
							dispatch({
								type: actionTypes.SET_ERROR_MSG,
								data: errorDesc,
							});
						}
						if (errorCode && AuthToasterError.includes(errorCode)) {
							dispatch({
								type: actionTypes.NOTIFICATION,
								statusMessage: errorDesc,
								statusType: StatusType.Error,
							});
						} else {
							handleError && handleError(result);
						}
						logger.trackError(
							`k1 shareholder verifyMobileOTP failed with error ${errorDesc} for clientId: ${clientId}`,
						);
					}
				})
				.catch(function (error: any) {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: OTPMobilePageConstants.ErrorMessage.serverError,
						statusType: StatusType.Error,
					});
					logger.trackWarning(
						`k1 shareholder verifyMobileOTP failed with error ${error.message} for clientId: ${clientId}`,
					);
				});
		},
	generateOTP:
		(clientId?: string): AppThunkAction<any> =>
		(dispatch, getState) => {
			return initializeAxios(clientId)
				.postJson<any>(null, '/api/K1/Generate/' + clientId)
				.then(function (response: any) {
					let result = response.data;
					if (result) {
						dispatch({
							type: actionTypes.NOTIFICATION,
							statusMessage: OTPPageConstants.SuccessMessage.OTPGenerateSuccess,
							statusType: StatusType.Success,
						});
					} else {
						dispatch({
							type: actionTypes.NOTIFICATION,
							statusMessage: OTPPageConstants.ErrorMessage.OTPGenerateFailed,
							statusType: StatusType.Error,
						});
					}
				})
				.catch(function (error: any) {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: OTPPageConstants.ErrorMessage.serverError,
						statusType: StatusType.Error,
					});
					logger.trackWarning(
						`k1 shareholder generateOTP failed with error ${error.message} for clientId: ${clientId}`,
					);
				});
		},
	verifyOTP:
		(
			OTP: string,
			clientId: string,
			handleVerify?: (guid: string) => void,
			handleError?: (result?: any) => void,
		): AppThunkAction<any> =>
		(dispatch, getState) => {
			//make the api url changes
			return initializeAxios(clientId)
				.postJson<any>('"' + OTP + '"', `/api/k1/Validate/${clientId}`)
				.then(function (response: any) {
					let result = response.data;

					if (result.isSuccess) {
						if (handleVerify !== undefined) {
							localStorage.set('isLoggedIn', true);
							handleVerify(result.data);
						}
					} else {
						let errorDesc = result.errorDescription;
						let errorCode = result.errorCode;

						if (errorCode && DisplayError.includes(errorCode)) {
							dispatch({
								type: actionTypes.SET_ERROR_MSG,
								data: errorDesc,
							});
						}
						if (errorCode && AuthToasterError.includes(errorCode)) {
							dispatch({
								type: actionTypes.NOTIFICATION,
								statusMessage: errorDesc,
								statusType: StatusType.Error,
							});
						} else {
							handleError && handleError(result);
						}
						logger.trackWarning(`k1 shareholder verifyOTP failed with error ${errorDesc} for clientId: ${clientId}`);
					}
				})
				.catch(function (error: any) {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: OTPPageConstants.ErrorMessage.serverError,
						statusType: StatusType.Error,
					});
					logger.trackWarning(`k1 shareholder verifyOTP failed with error ${error.message} for clientId: ${clientId}`);
				});
		},
};

export const reducer: Reducer<IOTPPage> = (state: IOTPPage = OTPPage, incomingAction: Action) => {
	const action = incomingAction as any;
	const currentState = Object.assign({}, state);
	switch (action.type) {
		case actionTypes.START_FETCH_INFO:
			return { ...currentState, loading: true };
		case actionTypes.SET_MOBILE_NO:
			if (action.data !== undefined && action.data !== null) {
				return { ...currentState, mobileNo: action.data, loading: false };
			}
			return currentState;
		case actionTypes.STOP_OTP_LOADER:
			return { ...currentState, loading: false };
		default:
			return currentState;
	}
};
